import * as React from 'react'
import MainPage from './MainPage';
import autobind from 'autobind-decorator';
import UploadPage from './UploadPage';


export class Controller extends React.Component {

    private _state = "main"

    private _mainPage: MainPage;

    @autobind
    changeState(state: string) {
        this._state = state; 
        this.forceUpdate();
    }

    onLoad() {
        this._mainPage.onLoad();
    }

    render() {

        var page: any;
        
        if(this._state =="main") {
            page =  <MainPage changeState={this.changeState} ref={it => this._mainPage = it}/>
        }

        if(this._state == "upload") {
            page = <UploadPage changeState={this.changeState}/>
        }

        return (
            <React.Fragment>

               {page}

            </React.Fragment>
        )
    }
}