import * as React from 'react'
import styled from 'styled-components'
import curtain from './images/curtain.jpg'
import backdrop from './images/backdrop.jpg'
import autobind from 'autobind-decorator'
import { getURL } from './util';


const CurtainImage = styled('img')({
    position: 'absolute',
    width: '100%', 
    transition: 'all 9000ms linear',
    zIndex: 500,
    '&.curtain-up': {
        transform: 'translateY(-100%)'
    }
})
const CurtainBackdrop = styled('div')({
    position: 'absolute',
    width: '100%', 
    height: '100%',
    transition: 'all 9000ms linear',
    zIndex: 200,
    background: 'black',
    pointerEvents: 'none',
    '&.curtain-up': {
        background: 'transparent',
    }
})

const VideoPlayer = styled('video')({
    position: 'absolute',
    width: '720px', 
    background: `transparent no-repeat url(${backdrop})`,
    backgroundSize: '100%',
    '@media(max-width:960px)': {
        width: '95%'
    },

    '&.backdrop-hidden': {
        background: 'black'
    }
})


const VideoContainer = styled('div')({
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    height: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    position: 'relative',
})


const Icon = styled('i')({
    color: 'rgb(250, 250, 250)', 
    cursor: 'pointer', 
    transition: 'all 150ms ease-in-out',
    position: 'absolute',
    transform: 'scale(1.8)', 
    ':hover': {
        transform: 'scale(2)',
        color: 'rgb(255, 255, 255)'
    }
})

const TopTextContainer = styled('div')({
    position: 'fixed',
    left: 0, 
    top: 0, 
    display: 'flex', 
    flexDirection: 'column', 
    paddingLeft: '38px', 
    paddingRight: '38px', 
    paddingTop: '24px', 
    userSelect: 'none'
})

const Headline = styled('h1')({
    color: 'rgb(250, 250, 250)', 
    fontSize: '36px', 
    fontFamily: 'BankGothic', 
    cursor: 'pointer', 
    transition: 'all 150ms ease-in-out',
    ':hover': {
        transform: 'scale(1.1)',
        color: 'rgb(255, 255, 255)'
    }
})

const SubHeadline = styled('h2')({
    color: 'rgb(250, 250, 250)', 
    fontSize: '17px', 
    fomtFamily: 'BankGothic', 
    marginLeft: '-6px',
    marginTop: '12px', 
    transition: 'all 250ms ease-in-out', 
    position: 'relative', 

    '&.hidden': {
        opacity: 0, 
        transform: 'translateY(-18px)'
    }
})

const BottomContainer = styled('div')({
    position: 'fixed', 
    bottom: 0, 
    left: 0,
    width: '100%',
    height: '128px',
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'flex-end', 
    alignItems: 'center'
})


const MailIcon = styled('div')({
    color: 'rgb(250, 250, 250)', 
    cursor: 'pointer', 
    transition: 'all 150ms ease-in-out',
    transform: 'scale(1.8)', 
    marginRight: '64px',
    ':hover': {
        transform: 'scale(2)',
        color: 'rgb(255, 255, 255)'
    }
})

const MailLinks = styled('div')({
    position: 'relative', 
    transform: 'translate3D(-30px, 0px, 0px)',
    color: 'rgb(250, 250, 250)', 
    transition: 'all 250ms ease-in-out',

    '&.hidden': {
        opacity: 0, 
        transform: 'translate3D(24px, 0px, 0)'
    }
    
})

const MailLink = styled('a')({
    color: 'rgb(250, 250, 250)', 
    textDecoration: 'none', 
    cursor: 'pointer', 
    transition: 'all 150ms ease-in-out',
    position: 'relative',
    
})

type Props = {
    changeState: (str: string) => void
}

type ControlsType = "none"|"start"|"replay"

export default class MainPage extends React.Component<Props, {}> {

    private _timeout: NodeJS.Timeout;
    private _className = "";
    private _controls: ControlsType = "start";
    private _video: HTMLVideoElement;
    private _subHeadlineHidden = true;
    private _mailHidden = true;

    private _clickCount = 0;
    private _clickTimeout: NodeJS.Timeout;

    _loaded = false;

    componentDidMount() {
       // document.addEventListener('click', this.handleOutsideClick);
    }

    @autobind
    handleOutsideClick() {
        this._subHeadlineHidden = true; 
        this._mailHidden = true; 

        this.forceUpdate();
    }

    componentWillUnmount() {
        clearTimeout(this._timeout);
       // document.removeEventListener('click', this.handleOutsideClick);
    }

    @autobind
    handlePlay() {
        this._controls = "none"; 
        if(this._video) {
            this._video.play();
        }
        this.forceUpdate();
    }

    @autobind 
    handleHeadlineEnter() {
        this._subHeadlineHidden = false;
        this.forceUpdate();
    }

    @autobind 
    handleHeadlineLeave() {
        this._subHeadlineHidden = true;
        this.forceUpdate();
    }

    @autobind 
    handleMailEnter() {
        this._mailHidden = false;
        this.forceUpdate();
    }

    @autobind 
    handleMailLeave() {
        this._mailHidden = true;
        this.forceUpdate();
    }

    @autobind
    handleVideoEnd() {
        this._video.load();
        this._controls = "replay"; 
        this.forceUpdate();
    }

    @autobind 
    handleMailClick(ev) {
        this._mailHidden = false;
        this.forceUpdate();
        ev.stopPropagation();
    }

    @autobind
    handleTitleClick(ev) {

        ev.stopPropagation();

        this._subHeadlineHidden = false;
        this.forceUpdate();

        if(this._clickCount == 0) {
            setTimeout(()=> this._clickCount = 0, 1500)
        }
        this._clickCount++; 

        if(this._clickCount == 8 && this.props.changeState) {
            this.props.changeState("upload")
        }   

    }

    getControls(): any {
        if(this._controls == "none") {
            return null; 
        }

        if(this._controls == "start") {
            return <Icon className="material-icons" onClick={this.handlePlay}>play_circle_filled</Icon>
        }

        if(this._controls == "replay") {
            return <Icon className="material-icons" onClick={this.handlePlay}>replay</Icon>
        }
    }

    getVideoClass(): any {
        if(this._controls == "none") {
            return "backdrop-hidden"; 
        }

        return "";
    }

    getBackdrop(): any {
        if(this._controls == "none") {
            return null; 
        }

        return backdrop;
    }

    onLoad() {
        if(this._loaded) {
            return;
        }
        this._timeout = setTimeout(()=> {
            this._className = "curtain-up"; 
            this.forceUpdate();
        }, 2000)

        this._loaded = true;
    }


    render() {

        return (
            <div>
                <CurtainImage className={this._className} src={curtain}/>
                <CurtainBackdrop  className={this._className}/>
               
                <VideoContainer onClick={this.handleOutsideClick}>
                    
                    <VideoPlayer className={this.getVideoClass()} poster={this.getBackdrop()} onEnded={this.handleVideoEnd} ref={it=> this._video = it} controls={this._controls == "none"} src={getURL('/file')}/>
                    {this.getControls()}
                </VideoContainer>

                <TopTextContainer>
                    <Headline onClick={this.handleTitleClick} onMouseEnter={this.handleHeadlineEnter} onMouseLeave={this.handleHeadlineLeave}> Ruben Flores</Headline>
                    <SubHeadline className={this._subHeadlineHidden? "hidden": ""}>PRODUCER | WRITER | DIRECTOR </SubHeadline>
                </TopTextContainer>

                <BottomContainer onMouseLeave={this.handleMailLeave}>
                    <MailLinks className={this._mailHidden? "hidden": ""}> 
                        <MailLink href="mailto:Ruben.Flores@mail.com">
                            Ruben.Flores@mail.com
                        </MailLink>
                    </MailLinks>
                    <MailIcon className="material-icons" onClick={this.handleMailEnter} onMouseEnter={this.handleMailEnter}>mail</MailIcon>
                    
                </BottomContainer>

            </div>
        )
    }
}