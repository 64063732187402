import * as React from 'react'
import * as ReactDOM from 'react-dom'
import './font.css'
import 'material-design-icons/iconfont/material-icons.css';
import './index.css'

import { Controller } from './src/Controller';

var controller: Controller

function bootstrap() {

    var content = document.querySelector('#app-content');
    ReactDOM.render(<Controller ref={it => controller = it}/>, content);
}


document.addEventListener("DOMContentLoaded", bootstrap);

window.onload = () => {
    document.getElementsByTagName("html")[0].style.visibility = "visible";
    controller.onLoad();
    
}