import * as React from 'react'
import autobind from 'autobind-decorator';
import axios from 'axios'
import styled from 'styled-components'
import { getURL } from './util';


const UploadButton = styled('button')({
    outline: 'none', 
    border: 'none', 
    borderRadius: '6px', 
    background: 'rgb(250, 250, 250)', 
    color: 'black',
    fontSize: '22px', 
    height: '46px',
    width: '180px', 
    cursor: 'pointer', 
    transition: 'all 150ms ease-in-out', 
    ':hover': {
        transform: 'scale(1.05)'
    }
})

const InputField = styled('input')({
    outline: 'none', 
    border: '1px solid white', 
    background: 'rgba(0, 0, 0, 0)',
    height: '46px',
    width: '360px', 
    marginBottom: '38px',
    fontSize: '22px', 
    color: 'white',
    paddingLeft: '24px'
})

const Container = styled('div')({
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
    position: 'fixed', 
    left: 0, 
    top: 0, 
    width: '100%', 
    height: '100%'
})

const ChooseFileText = styled('p')({
    color: 'white', 
    fontSize: '22px', 
    userSelect: 'none', 
    cursor: 'pointer',
    marginBottom: '64px',
    transition: 'all 150ms ease-in-out', 
    ':hover': {
        transform: 'scale(1.05)'
    }

})

const FileText = styled('p')({
    color: 'rgb(200, 200, 200)', 
    fontSize: '16px', 
    userSelect: 'none', 
    cursor: 'pointer',
    marginBottom: '24px',
})

type Props = {
    changeState: (str: string) => void
}

export default class UploadPage extends React.Component<Props, {}> {

    private _files: FileList;
    private _password = ""
    private _uploading = false;
    private _uploadDone = false;
    private _uploadError = false;
    private _percent = 0;


    @autobind
    handleUpload() {

        if(this._uploadDone) {

            if(this.props.changeState) {
                this.props.changeState("main")
            }
            return;
        }

        if(this._uploading) {
            return;
        }

        if (this._files && this._files.length) {

            this._uploading = true;

            const data = new FormData();
            data.append('file', this._files[0], this._files[0].name);

            axios.get(getURL('/authCheck'), {
                headers: {'X-Auth-Token': this._password},
            }).then(res => {
                if(res.data.error == true) {
                    this._uploadError = true;
                    this._uploadDone = false;
                    this._uploading = false; 
                    this.forceUpdate();
                } else {
                    axios.post(getURL('/file'), data, {
                        headers: {'X-Auth-Token': this._password},
        
                        onUploadProgress: ProgressEvent => {
                            var loaded = (ProgressEvent.loaded / ProgressEvent.total*100);
                            this._percent = loaded; 
                            this.forceUpdate();
                        },
                        
                    }).then(res => {
                        this._uploading = false; 
                        this._uploadDone = true;
                        if(res.data.error == true) {
                            this._uploadError = true;
                            this._uploadDone = false;
                        }
                       
                        this.forceUpdate();
                    })
                }
            })
        } 
    }

    @autobind
    handleChooseFile() {

        if(this._uploading) {
            return;
        }

        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = () => {
            var files = input.files
           
            if (files && files.length) {

               this._files = files;
               this._uploadDone = false;
               this._uploadError = false
               
            } else {
                this._files = null;
            }

            this.forceUpdate();
        }

        input.click();

    }

    @autobind
    handlePasswordChange(event) {
        this._password = event.target.value;
        this.forceUpdate();
    }

    getFileText(): string {

        if(this._uploadError) {
            return 'There was an error with your file upload. Please check the password'
        }

        if(this._uploadDone) {
            return 'Upload Complete'
        }

        if(this._uploading) {
            return `Uploading: ${this._percent.toFixed(2)}%`
        } else {
            return this._files == null || this._files.length == 0? "No File Chosen": this._files[0].name
        }
    }


    render() {
        return (
            <Container>
                <FileText> {this.getFileText()} </FileText>
                <ChooseFileText onClick={this.handleChooseFile}> Choose File </ChooseFileText>
                <InputField type="password" placeholder="PASSWORD" value={this._password} onChange={this.handlePasswordChange}/>
                <UploadButton onClick={this.handleUpload}> {this._uploadDone? "GO BACK": "UPLOAD" } </UploadButton>
            </Container>
        )
    }
}